export const useLocalStorage = () => {
  const setJSONValue = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const getJSONValue = (key: string) => {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }

  return {
    setJSONValue,
    getJSONValue,
  }
}
