import {
  cn,
  Divider,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useBreakpoints,
} from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { promptsByUseCase } from '../constants/promptData'
import { OnboardingItemCard, OnboardingItemCardWrapper } from '@/ui-components/OnboardingCard'

type Props = {
  onUpdateForm: (field: string, value: string) => void
  onPromptSelect?: (prompt: string) => void
  onUseCaseSelect?: (useCase: string) => void
  onPromptChange?: (prompt: string) => void
  form: {
    useCase: string
    context: string
  }
}

export const TemplateGenerationPromptStage = ({
  onUpdateForm,
  onPromptSelect,
  onUseCaseSelect,
  onPromptChange,
  form,
}: Props) => {
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null)
  const debounceTimerRef = useRef<NodeJS.Timeout>()

  const currentPrompts = useMemo(() => promptsByUseCase[form.useCase] || [], [form.useCase])

  const handlePromptSelect = useCallback(
    (promptName: string) => {
      const selectedPromptData = currentPrompts.find((p) => p.promptName === promptName)
      if (selectedPromptData) {
        setSelectedPrompt(promptName)
        onUpdateForm('context', selectedPromptData.prompt)
        onPromptSelect?.(selectedPromptData.prompt)
      }
    },
    [onUpdateForm, currentPrompts, onPromptSelect],
  )

  const handleContextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newContext = e.target.value
      setSelectedPrompt(null)
      onUpdateForm('context', newContext)

      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }

      debounceTimerRef.current = setTimeout(() => {
        onPromptChange?.(newContext)
      }, 3000)
    },
    [onUpdateForm, onPromptChange],
  )

  const handleUseCaseChange = useCallback(
    (useCase: string) => {
      onUpdateForm('useCase', useCase)
      onUseCaseSelect?.(useCase)
    },
    [onUpdateForm, onUseCaseSelect],
  )

  // Keep selected prompt in sync with form context
  useEffect(() => {
    const matchingPrompt = currentPrompts.find((p) => p.prompt.trim() === form.context)
    if (!matchingPrompt) {
      setSelectedPrompt(null)
    }
  }, [form.context, currentPrompts])

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }
    }
  }, [])

  return (
    <div className='flex flex-col gap-6 w-full'>
      <div className='flex flex-col items-center justify-center mt-4'>
        <span className='text-xs font-semibold text-grey-s6'>
          What is the purpose of this template?
        </span>

        <UseCaseSelector
          selectedUseCase={form.useCase as UseCase}
          onUseCaseSelect={handleUseCaseChange}
        />

        {/* Prompt Input */}
        <div className='flex flex-col w-full rounded-lg bg-white/50 p-8 mt-8 items-center justify-center gap-3'>
          <span className='text-xs font-semibold text-grey-s6'>Specify use case</span>
          <Textarea
            className='text-xs text-grey-s5 bg-white border-grey-s2'
            rows={5}
            placeholder='Write instructions...'
            value={form.context}
            onChange={handleContextChange}
          />

          <span className='text-xs text-grey-s5'>Or start with an example</span>

          <div className='flex flex-row tall:flex-col w-full gap-3'>
            {currentPrompts.map((card) => (
              <PromptCard
                key={card.promptName}
                {...card}
                isSelected={selectedPrompt === card.promptName}
                onSelect={() => handlePromptSelect(card.promptName)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const MobileTemplateGenerationUseCaseStage = ({
  onUpdateForm,
  onUseCaseSelect,
  form,
}: Pick<Props, 'form' | 'onUpdateForm' | 'onUseCaseSelect'>) => {
  const handleUseCaseChange = useCallback(
    (useCase: string) => {
      onUpdateForm('useCase', useCase)
      onUseCaseSelect?.(useCase)
    },
    [onUpdateForm, onUseCaseSelect],
  )

  return (
    <div className='flex flex-col gap-6 w-full'>
      <span className='text-sm font-semibold text-grey-s6 text-center'>
        What is the purpose of this template?
      </span>

      <UseCaseSelector
        selectedUseCase={form.useCase as UseCase}
        onUseCaseSelect={handleUseCaseChange}
      />
    </div>
  )
}

export const MobileTemplateGenerationPromptSelectStage = ({
  onUpdateForm,
  onPromptSelect,
  form,
}: Pick<Props, 'onUpdateForm' | 'form' | 'onPromptSelect'>) => {
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null)

  const currentPrompts = useMemo(() => promptsByUseCase[form.useCase] || [], [form.useCase])

  const handlePromptSelect = useCallback(
    (promptName: string) => {
      const selectedPromptData = currentPrompts.find((p) => p.promptName === promptName)
      if (selectedPromptData) {
        setSelectedPrompt(promptName)
        onUpdateForm('context', selectedPromptData.prompt)
        onPromptSelect?.(selectedPromptData.prompt)
      }
    },
    [onUpdateForm, currentPrompts, onPromptSelect],
  )

  // Keep selected prompt in sync with form context
  useEffect(() => {
    const matchingPrompt = currentPrompts.find((p) => p.prompt.trim() === form.context)
    if (!matchingPrompt) {
      setSelectedPrompt(null)
    } else {
      setSelectedPrompt(matchingPrompt.promptName)
    }
  }, [form.context, currentPrompts])

  return (
    <div className='flex flex-col gap-6 w-full'>
      <div className='flex flex-col text-center text-sm gap-1'>
        <span className='font-semibold text-grey-s6'>Select use case</span>
        <span className='text-grey-s5 text-xs'>you can refine prompt in next step</span>
      </div>

      <div className='flex flex-col gap-6'>
        {currentPrompts.map((card) => (
          <PromptCard
            key={card.promptName}
            {...card}
            isSelected={selectedPrompt === card.promptName}
            onSelect={() => handlePromptSelect(card.promptName)}
          />
        ))}
      </div>
    </div>
  )
}

export const MobileTemplateGenerationPromptChangeStage = ({
  onPromptChange,
  onUpdateForm,
  form,
}: Pick<Props, 'onUpdateForm' | 'onPromptChange' | 'form'>) => {
  const debounceTimerRef = useRef<NodeJS.Timeout>()

  const handleContextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newContext = e.target.value
      onUpdateForm('context', newContext)

      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current)
      }

      debounceTimerRef.current = setTimeout(() => {
        onPromptChange?.(newContext)
      }, 3000)
    },
    [onUpdateForm, onPromptChange],
  )

  return (
    <div className='flex flex-col gap-6 w-full'>
      <span className='font-semibold text-grey-s6 text-center text-sm'>
        Refine prompt if needed
      </span>

      <Textarea
        className='text-xs text-grey-s5 bg-white border-grey-s2'
        rows={14}
        placeholder='Write instructions...'
        value={form.context}
        onChange={handleContextChange}
      />
    </div>
  )
}

const PromptCard = ({
  promptName,
  description,
  prompt,
  isSelected,
  onSelect,
}: {
  promptName: string
  description: string
  prompt: string
  isSelected: boolean
  onSelect: () => void
}) => {
  const { isMobile } = useBreakpoints()

  return (
    <div
      className={cn(
        'w-full lg:flex-1 rounded-md bg-white/85 hover:bg-white/50 transition-colors cursor-pointer',
        {
          'outline outline-2 outline-ai-s4-from bg-white/50': isSelected,
        },
      )}
      onClick={onSelect}
    >
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild className='text-xs'>
          <div className='flex flex-col p-3 justify-start items-start w-full'>
            <span className='font-semibold text-grey-s6 text-left'>{promptName}</span>
            <span className='text-grey-s5 text-left'>{description}</span>

            {isMobile && isSelected && (
              <div className='flex flex-col gap-2 w-full mt-2'>
                <Divider />

                <span className='text-xs text-grey-s5'>
                  <span className='font-medium text-grey-s6'>Prompt: </span>
                  {prompt}
                </span>
              </div>
            )}
          </div>
        </TooltipTrigger>

        <TooltipContent side='right' className='p-0 shadow-md'>
          <div className='flex flex-col gap-2 p-6 bg-white rounded-md'>
            <span className='text-xs font-semibold text-grey-s6'>{promptName}</span>
            <span className='max-w-md text-xs text-grey-s5'>{prompt}</span>
          </div>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}

type UseCase = 'onboarding' | 'sales' | 'other'

const UseCaseSelector = ({
  selectedUseCase,
  onUseCaseSelect,
}: {
  selectedUseCase: UseCase
  onUseCaseSelect: (useCase: UseCase) => void
}) => {
  return (
    <OnboardingItemCardWrapper>
      <OnboardingItemCard
        title='Onboarding'
        icon='c_team-work'
        isSelected={selectedUseCase === 'onboarding'}
        onSelect={() => onUseCaseSelect('onboarding')}
      />
      <OnboardingItemCard
        title='Sales'
        icon='c_virtual-meeting'
        isSelected={selectedUseCase === 'sales'}
        onSelect={() => onUseCaseSelect('sales')}
      />
      <OnboardingItemCard
        title='Other'
        icon='c_surfing-chart'
        isSelected={selectedUseCase === 'other'}
        onSelect={() => onUseCaseSelect('other')}
      />
    </OnboardingItemCardWrapper>
  )
}
