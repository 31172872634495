import { TAsset, TEmbedType } from '@valuecase/common'

export const MSWORD_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
export const MSPOWERPOINT_MIME_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]
export const MSEXCEL_MIME_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const MIME_TYPES_WITH_PREVIEW = [
  'image/*',
  'video/*',
  'application/pdf',
  ...MSWORD_MIME_TYPES,
  ...MSPOWERPOINT_MIME_TYPES,
]

export function getEmbedTypeDescription(embedType: TEmbedType): string {
  switch (embedType) {
    case 'youtube':
      return 'YouTube'
    case 'loom':
      return 'Loom'
    case 'calendly':
      return 'Calendly'
    case 'hubspot':
      return 'HubSpot'
    case 'zoom':
      return 'Zoom'
    case 'html':
      return 'HTML'
    case 'vimeo':
      return 'Vimeo'
    case 'meet':
      return 'Google Meet'
    case 'drive':
      return 'Google Drive'
    case 'vidyard':
      return 'Vidyard'
    case 'wistia':
      return 'Wistia'
    default:
      return 'Embed'
  }
}

export function assetDescription(asset: TAsset) {
  const { type, data } = asset

  if (type === 'LINK') {
    return 'Link'
  }

  if (type === 'EMBED' || type === 'EMBEDDABLE_LINK') {
    return getEmbedTypeDescription(data.embedType)
  }

  return FileHelper.getFileTypeDescription(data.mimeType)
}

export class FileHelper {
  static pdfPreviewMIMETypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
  ]

  static fileSupportsPreview(typeOfFile?: string): boolean {
    return this.filePreviewType(typeOfFile) !== 'NO_PREVIEW'
  }

  static filePreviewType(
    typeOfFile?: string,
  ): 'NO_PREVIEW' | 'PREVIEW_AFTER_UPLOAD' | 'LIVE_PREVIEW' {
    if (!typeOfFile) {
      return 'NO_PREVIEW'
    }

    if (
      typeOfFile.startsWith('image/') ||
      typeOfFile.startsWith('application/pdf') ||
      typeOfFile.startsWith('video/')
    ) {
      return 'LIVE_PREVIEW'
    }

    if (this.pdfPreviewMIMETypes.includes(typeOfFile)) {
      return 'PREVIEW_AFTER_UPLOAD'
    }

    return 'NO_PREVIEW'
  }

  static isPdf(typeOfFile?: string): boolean {
    return !!typeOfFile && typeOfFile === 'application/pdf'
  }

  static async loadFileFromUrl(url: string, init?: RequestInit): Promise<File> {
    const response = await fetch(url, init)
    const blob = await response.blob()
    return new File([blob], 'test', {
      type: blob.type,
    })
  }

  static async getFileTypeForUrl(url: string, init?: RequestInit): Promise<string | null> {
    const file = await FileHelper.loadFileFromUrl(url, init)
    return FileHelper.getFileTypeDescriptionForFile(file)
  }

  static getFileTypeDescriptionForFile(file: File | null | undefined): string | null {
    if (!file) {
      return null
    }
    return FileHelper.getFileTypeDescription(file.type)
  }

  static getFileTypeDescription(mimeType: string | null | undefined): string {
    if (!mimeType) {
      return 'File'
    }

    if (mimeType.endsWith('/pdf')) {
      return 'PDF'
    }

    if (MSWORD_MIME_TYPES.includes(mimeType)) {
      return 'Word Document'
    }

    if (MSPOWERPOINT_MIME_TYPES.includes(mimeType)) {
      return 'PowerPoint'
    }

    if (MSEXCEL_MIME_TYPES.includes(mimeType)) {
      return 'Excel'
    }

    const [[firstLetterOfFileCategory, ...otherLetters], fileType] = mimeType.split('/')
    const capitalizedFileCategory = `${firstLetterOfFileCategory?.toUpperCase()}${otherLetters.join(
      '',
    )}`

    if (fileType && capitalizedFileCategory) {
      return `${fileType.toUpperCase()} ${capitalizedFileCategory}`
    }

    return 'File'
  }

  static fileNameAndExtension(fileName: string) {
    const fileExtension = (fileName.includes('.') ? fileName.split('.').pop() : null) ?? null

    return {
      fileName: fileName.replace(`.${fileExtension}`, ''),
      fileExtension,
    }
  }

  static maybeRewriteFilenameWithCustomName(fileName: string, customNameWithoutExtension?: string) {
    if (!customNameWithoutExtension) {
      return fileName
    }

    const { fileExtension } = FileHelper.fileNameAndExtension(fileName)
    if (!fileExtension) {
      return customNameWithoutExtension
    }

    return [customNameWithoutExtension, fileExtension].join('.')
  }
}
