import { useMemo } from 'react'
import { useSpacesQuery } from '../spaces/hooks/useReadSpaces'
import { useAuthState } from '@/auth/auth'
import { useHasPermission } from '@/auth/permissions'

export const useProgressBarQuery = ({
  spaceCreatedAfter,
  ownerIds,
}: {
  spaceCreatedAfter?: Date
  ownerIds?: string[]
}) => {
  const auth = useAuthState()
  const hasSpacesAllAdministrationPermission = useHasPermission('SPACES_ALL_ADMINISTRATION')
  const ownerIdsOrEmpty = useMemo(() => {
    if (hasSpacesAllAdministrationPermission) {
      return ownerIds || []
    }

    return auth.authorized ? [auth.sub] : []
  }, [auth, hasSpacesAllAdministrationPermission, ownerIds])

  const { spaces, isLoading } = useSpacesQuery({
    filters: {
      spaceType: 'active',
      ownerIds: ownerIdsOrEmpty,
      createdAfter: spaceCreatedAfter,
    },
    sorting: {
      sortBy: 'updatedAt',
      sortDirection: 'desc',
    },
    page: 1,
    pageSize: 2000,
    includeSpaceScore: false,
    enabled: auth.authorized,
    keepPreviousData: true,
  })

  const result = useMemo(() => ({ spaces, isLoading }), [spaces, isLoading])
  return result
}
