import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuButton,
  DropdownMenuContentScrollable,
} from './dropdown-menu'
import { Highlight } from './text'
import { cn } from '../../lib'
import { useState, useMemo, useCallback, ReactNode, createContext, useContext } from 'react'
import { Button2 } from './button'
import { Badge } from './badge'
import { Command, CommandCheckboxItem, CommandEmpty, CommandInput, CommandList } from './command'
import { EmptyState, EmptyStateDescription, EmptyStateHeader, EmptyStateTitle } from './empty'

type FilterContextType = {
  selectedIds: Set<string> | undefined
  toggleItem: (id: string) => void
  searchTerm: string
}

const FilterContext = createContext<FilterContextType | undefined>(undefined)

export const useFilterContext = () => {
  const context = useContext(FilterContext)
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterDropdown')
  }
  return context
}

export type FilterCheckboxItemProps = {
  id: string
  children: ReactNode
  className?: string
}

export const FilterCheckboxItem = ({
  id,
  children,
  className,
}: FilterCheckboxItemProps): JSX.Element => {
  const { selectedIds, toggleItem, searchTerm } = useFilterContext()

  return (
    <CommandCheckboxItem
      key={id}
      selected={selectedIds?.has(id) || false}
      onSelect={() => toggleItem(id)}
      className={cn('text-sm mt-1', className)}
    >
      <Highlight searchTerm={searchTerm}>{children}</Highlight>
    </CommandCheckboxItem>
  )
}

export type FilterDropdownItem = {
  id: string
  title: string
}

export type FilterDropdownProps<T extends string> = {
  selectedIds: Set<T> | undefined
  setSelectedIds: (ids: Set<T> | undefined) => void
  labelForNoSelection: ReactNode
  labelForSelection: ReactNode
  searchPlaceholder?: string
  disabled?: boolean
  children?: ReactNode
}

export const FilterDropdown = <T extends string>({
  selectedIds,
  labelForNoSelection,
  labelForSelection,
  searchPlaceholder,
  setSelectedIds,
  disabled,
  children,
}: FilterDropdownProps<T>): JSX.Element => {
  const hasSelection = useMemo(() => selectedIds && selectedIds.size >= 1, [selectedIds])
  const [searchTerm, setSearchTerm] = useState('')

  const toggleItem = useCallback(
    (id: T) => {
      const newSet = new Set<T>(selectedIds)
      if (newSet.has(id)) {
        newSet.delete(id)
      } else {
        newSet.add(id)
      }
      setSelectedIds(newSet.size === 0 ? undefined : newSet)
    },
    [selectedIds, setSelectedIds],
  )

  const label = useMemo(() => {
    const count = selectedIds?.size || 0
    if (count === 0) {
      return labelForNoSelection
    }
    return (
      <span>
        {labelForSelection}
        <Badge variant={'solid'} className='ml-2'>
          {count}
        </Badge>
      </span>
    )
  }, [selectedIds?.size, labelForNoSelection, labelForSelection])

  const contextValue = useMemo(() => {
    return {
      selectedIds,
      toggleItem,
      searchTerm,
    }
  }, [selectedIds, toggleItem, searchTerm])

  return (
    <FilterContext.Provider value={contextValue as FilterContextType}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={disabled}>
          <DropdownMenuButton
            disabled={disabled}
            className={cn({
              'border-primary-s5 text-primary-s5 font-semibold': hasSelection,
            })}
            trailingIconClassName={cn({ 'text-primary-s5': hasSelection })}
          >
            {label}
          </DropdownMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className={'min-w-[284px]'} align='start'>
          <Command>
            <div className='p-1 flex flex-col gap-2'>
              <CommandInput
                className='mb-0'
                inputSize={'default'}
                value={searchTerm}
                onValueChange={setSearchTerm}
                placeholder={searchPlaceholder ?? 'Search...'}
              />
              <Button2
                variant='plain-subtle'
                size={'small'}
                disabled={!selectedIds}
                onClick={() => setSelectedIds(undefined)}
                leadingIcon='refresh-outline'
                className='w-full'
              >
                Reset and show all items
              </Button2>
            </div>
            <DropdownMenuContentScrollable className={'w-full'}>
              <CommandList>
                <CommandEmpty>
                  <EmptyState icon={'telescope'} iconSize={'small'} className={'my-4'}>
                    <EmptyStateHeader>
                      <EmptyStateTitle>Nothing here!</EmptyStateTitle>
                      <EmptyStateDescription>
                        You may want to check for typos, try another search
                      </EmptyStateDescription>
                    </EmptyStateHeader>
                  </EmptyState>
                </CommandEmpty>
                {children}
              </CommandList>
            </DropdownMenuContentScrollable>
          </Command>
        </DropdownMenuContent>
      </DropdownMenu>
    </FilterContext.Provider>
  )
}
