import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import {
  PaginationMeta,
  TActivitiesAcrossTenantSpacesQuery,
  WeaklyTypedActivity,
} from '@valuecase/common'

export function useActivities({
  activityTypes,
  ownerIds,
  itemsPerPage,
}: Omit<TActivitiesAcrossTenantSpacesQuery, 'page'>) {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['activities', activityTypes, ownerIds, itemsPerPage],
    queryFn: async ({ pageParam }) => {
      const urlParams = new URLSearchParams()

      if (activityTypes) {
        urlParams.append('activityTypes', activityTypes.join(','))
      }

      if (ownerIds) {
        urlParams.append('ownerIds', ownerIds.join(','))
      }

      urlParams.append('itemsPerPage', itemsPerPage.toString())
      urlParams.append('page', (pageParam || 1).toString())

      const response = await spacesApi.get(`/activity/all-across-spaces?${urlParams.toString()}`)
      return response.data as PaginationMeta<WeaklyTypedActivity, any>
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1
      }
      return undefined
    },
    retry: 1,
    staleTime: 10000 * 60, // 60 seconds
  })

  return {
    ...rest,
    activities: data,
  }
}

export function activityFeedIndicatorQueryFn() {
  return () => spacesApi.get(`/activity/own-unread-count`).then((res) => res.data)
}

export function useActivityFeedCount() {
  const queryKey = ['activityFeedCount']
  const query = useQuery({
    queryKey,
    queryFn: activityFeedIndicatorQueryFn(),
    retry: 1,
    staleTime: 1000 * 10, // 10 seconds
    refetchInterval: 1000 * 30, // 30 seconds
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    refetch: query.refetch,
    error: query.error,
    unreadCount: query.data,
    isFetched: query.isFetched,
  }
}
