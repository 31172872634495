import { useEffect, useState } from 'react'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import SpacesList from './components/spacesList/spacesList'

export const Spaces = () => {
  const [mixpanel_pageVisit_Tracked, set_mixpanel_pageVisit_Tracked] = useState(false)
  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    if (!mixpanel_pageVisit_Tracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Space List',
        },
      })
      set_mixpanel_pageVisit_Tracked(true)
    }
  }, [mixpanel_pageVisit_Tracked, trackEvent])

  return (
    <div data-intercom-target='Spaces List' className='flex flex-col gap-6 w-full flex-grow mt-11'>
      <SpacesList />
    </div>
  )
}
