import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  Button2,
} from '@valuecase/ui-components'
import { FC, useCallback, useMemo } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { TaskRouteSearch } from '../navigation/Router'

// Labels expect always plural case due to available options
// If adding non-plural options, adjust the buttonLabelText logic
const allTime = 'All time'
const months12 = 'months12'
const months6 = 'months6'
const months3 = 'months3'
const days30 = 'days30'

export const TaskListTimeFilter: FC = () => {
  const { searchTimeRange } = useSearch({ from: '/dashboard/tasks/' })
  const navigate = useNavigate()

  const setSearchTimeRange = useCallback(
    (searchTimeRange?: TaskRouteSearch['searchTimeRange']) => {
      navigate({ to: '/tasks', search: (prev) => ({ ...prev, searchTimeRange }) })
    },
    [navigate],
  )

  const timeRange = useMemo(() => {
    if (!searchTimeRange) {
      return allTime
    }

    if ('days' in searchTimeRange && searchTimeRange.days === 30) {
      return days30
    }

    if (!('months' in searchTimeRange)) {
      throw new Error('Invalid search time range')
    }

    if (searchTimeRange.months === 12) {
      return months12
    }
    if (searchTimeRange.months === 6) {
      return months6
    }

    if (searchTimeRange.months === 3) {
      return months3
    }

    throw new Error('Invalid search time range')
  }, [searchTimeRange])

  const handleTimeRangeChange = useCallback(
    (value: string) => {
      switch (value) {
        case allTime:
          setSearchTimeRange(undefined)
          break
        case months12:
          setSearchTimeRange({ months: 12 })
          break
        case months6:
          setSearchTimeRange({ months: 6 })
          break
        case months3:
          setSearchTimeRange({ months: 3 })
          break
        case days30:
          setSearchTimeRange({ days: 30 })
          break
      }
    },
    [setSearchTimeRange],
  )

  const buttonLabelText = useMemo(() => {
    if (!searchTimeRange) {
      return allTime
    }
    // Always plural case due to available options
    if ('months' in searchTimeRange) {
      return `Spaces created in the last ${searchTimeRange.months} months`
    }
    if ('days' in searchTimeRange) {
      return `Spaces created in the last ${searchTimeRange.days} days`
    }
  }, [searchTimeRange])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button2
          variant='ghost'
          id='dashboard-tasks-spaces-created-after-filter'
          className={'max-w-72 gap-1'}
          trailingIcon='chevron-down'
        >
          {buttonLabelText}
        </Button2>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end'>
        <DropdownMenuRadioGroup value={timeRange} onValueChange={handleTimeRangeChange}>
          <DropdownMenuRadioItem value={allTime}>All time</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={months12}>Last 12 months</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={months6}>Last 6 months</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={months3}>Last 3 months</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={days30}>Last 30 days</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default TaskListTimeFilter
