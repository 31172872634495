import { OnboardingItemCard, OnboardingItemCardWrapper } from '@/ui-components/OnboardingCard'
import { useCallback } from 'react'

type Props = {
  onUpdateForm: (field: string, value: any) => void
  form: {
    isWebinarSignup?: boolean
  }
}

export const WebinarSignupStep = ({ form, onUpdateForm }: Props) => {
  const handleSelect = useCallback(
    (value: boolean) => {
      onUpdateForm('isWebinarSignup', value)
    },
    [onUpdateForm],
  )

  return (
    <div className='flex flex-col gap-10 w-full'>
      <OnboardingItemCardWrapper>
        <OnboardingItemCard
          title='Yes, send me an invite'
          icon='c_remote-team'
          onSelect={() => handleSelect(true)}
        />
        <OnboardingItemCard
          title="No, I'll pass for now"
          icon='c_working-fast'
          onSelect={() => handleSelect(false)}
        />
      </OnboardingItemCardWrapper>
      <p className='text-xs text-grey-s5 text-center'>
        Regardless of your choice, you&apos;re all set to continue with your account setup now.
      </p>
    </div>
  )
}
