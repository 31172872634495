import { useAuthState } from '../../../../auth/auth'
import Wave from './assets/wave.svg?react'
import TriggerCreateSpaceFlow from '../spaceCreationFlow/SpaceCreatorTrigger'
import { useHasPermission } from '../../../../auth/permissions'
import { useSearch } from '@tanstack/react-router'
import { useMemo } from 'react'
import { Divider } from '@valuecase/ui-components'

interface SpacesPageHeaderProps {
  isSpaceListEmpty: boolean
}

const EmptyStateHeader = ({ name }: { name: string }) => {
  return (
    <div className='flex flex-col gap-3 flex-grow items-center justify-center'>
      <Wave className='w-10 h-10' />
      <div className='font-light text-3xl flex w-full flex-col items-center justify-center'>
        <p>
          Welcome to Valuecase, <b className='font-bold'>{name}</b>
        </p>
        <p>Nice to meet you!</p>
      </div>
      <div className='text-sm text-center text-grey-s5'>
        You have no spaces yet, let&apos;s change that.
      </div>
    </div>
  )
}

const PopulatedStateHeader = ({
  name,
  hasPermissionToCreateSpaces,
  createSpaceFromTemplate,
}: {
  name: string
  hasPermissionToCreateSpaces: boolean
  createSpaceFromTemplate: string | undefined
}) => {
  return (
    <>
      <div className='flex justify-between items-end gap-6'>
        <div className='flex flex-col gap-2'>
          <div className='text-4xl font-light flex gap-4'>
            <span>
              Hey there, <span className='font-bold'>{name}</span>
            </span>
            <Wave className='w-10 h-10' />
          </div>
          <div className='text-sm text-grey-s5'>It&apos;s great to see you again!</div>
        </div>
        {hasPermissionToCreateSpaces && (
          <TriggerCreateSpaceFlow shouldOpenModalExternally={!!createSpaceFromTemplate} />
        )}
      </div>
      <Divider />
    </>
  )
}

export default function SpacesPageHeader({ isSpaceListEmpty }: SpacesPageHeaderProps) {
  const auth = useAuthState()
  const hasPermissionToCreateSpaces = useHasPermission('SPACES_CREATE')
  const searchParams = useSearch({ strict: false }) as unknown as {
    createSpaceFromTemplate?: string
  }

  const name = useMemo(() => {
    return auth.authorized ? auth.given_name ?? auth.name : ''
  }, [auth])

  if (isSpaceListEmpty) {
    return <EmptyStateHeader name={name} />
  }

  return (
    <PopulatedStateHeader
      name={name}
      hasPermissionToCreateSpaces={hasPermissionToCreateSpaces}
      createSpaceFromTemplate={searchParams.createSpaceFromTemplate}
    />
  )
}
