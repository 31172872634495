import { useNavigate } from '@tanstack/react-router'
import { useCallback, useMemo, useState } from 'react'
import { useTasksFilter } from './useTasksFilter'
import { useDebounceCallback } from 'usehooks-ts'
import { useHasPermission } from '@/auth/permissions'

import {
  Button2,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Input2,
  PanelTabsList,
  PanelTabsTrigger,
  Tabs,
} from '@valuecase/ui-components'

import { SpaceOwnerFilterDropdown } from '../filtering/SpaceOwnerFilterDropdown'
import { useAuthState } from '@/auth/auth'
import { getCleanSub } from '@valuecase/common'

export const TasksFilter = () => {
  const { view, ownerIds, search } = useTasksFilter()

  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState(search || '')

  const selectedOwners = useMemo(() => (ownerIds ? new Set(ownerIds) : undefined), [ownerIds])

  const setSearchDebounced = useDebounceCallback(
    (search: string) =>
      navigate({
        to: '/tasks',
        search: (prev) => ({ ...prev, search }),
      }),
    300,
  )

  const hasSpacesAllAdministrationPermission = useHasPermission('SPACES_ALL_ADMINISTRATION')
  const auth = useAuthState()
  const userId = useMemo(() => (auth.authorized ? getCleanSub(auth.sub) : null), [auth])

  const showResetButton = useMemo(() => {
    if (hasSpacesAllAdministrationPermission) {
      const selectedOwnersContainOther = [...(selectedOwners ?? [])].some((id) => id !== userId)
      return searchValue.length > 0 || !selectedOwners || selectedOwnersContainOther
    }

    return searchValue.length > 0
  }, [hasSpacesAllAdministrationPermission, searchValue.length, selectedOwners, userId])

  const resetFilters = useCallback(() => {
    setSearchValue('')
    navigate({
      to: '/tasks',
      search: (prev) => ({
        ...prev,
        ownerIds: hasSpacesAllAdministrationPermission && userId ? [userId] : undefined,
        search: undefined,
      }),
    })
  }, [hasSpacesAllAdministrationPermission, navigate, userId])

  const ownerFilter = useMemo(
    () => (
      <SpaceOwnerFilterDropdown
        selectedIds={selectedOwners}
        setSelectedIds={(ids) =>
          navigate({
            to: '/tasks',
            search: (prev) => ({ ...prev, ownerIds: ids ? [...ids] : undefined }),
          })
        }
      />
    ),
    [selectedOwners, navigate],
  )

  return (
    <div className='flex items-center gap-2 w-full justify-between'>
      <div className='flex items-center gap-2'>
        {view === 'list' && (
          <Input2
            leadingIcon='search'
            className='max-w-[200px]'
            placeholder='Search'
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
              setSearchDebounced(e.target.value)
            }}
          />
        )}
        {selectedOwners && selectedOwners.size > 0 && ownerFilter}
      </div>
      <div className='flex items-center gap-2'>
        {showResetButton && (
          <Button2 variant={'plain-subtle'} leadingIcon='refresh' onClick={resetFilters}>
            Reset
          </Button2>
        )}
        {hasSpacesAllAdministrationPermission && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button2 variant={'outlined-white'} leadingIcon='options' className='ml-auto'>
                Filters
              </Button2>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='w-[276px] flex flex-col gap-6 p-4' align='end'>
              {ownerFilter}
            </DropdownMenuContent>
          </DropdownMenu>
        )}

        <Tabs
          value={view}
          onValueChange={(v) =>
            navigate({
              to: '/tasks',
              search: (prev) => ({ ...prev, view: v as 'columns' | 'list' }),
            })
          }
        >
          <PanelTabsList>
            <PanelTabsTrigger leadingIcon='c_columns' value='columns' />
            <PanelTabsTrigger leadingIcon='list' value='list' />
          </PanelTabsList>
        </Tabs>
      </div>
    </div>
  )
}
