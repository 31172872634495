import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { ReadSpacesListDTO } from './types'
import { z } from 'zod'

export const spacesQueryFiltersSchema = z.object({
  spaceIds: z.array(z.string()).nullish(),
  spaceName: z.string().nullish(),
  spaceType: z.enum(['all', 'active', 'archived']).nullish().default('active'),
  ownerIds: z.array(z.string()).optional(),
  mapStatus: z
    .array(z.enum(['notCreated', 'notStarted', 'stalled', 'inProgress', 'overdue', 'completed']))
    .nullish(),
  templateIds: z.array(z.string()).nullish(),
  teamIds: z.array(z.string()).nullish(),
  collaboratorAccess: z.boolean().nullish(),
  myTeamAccess: z.boolean().nullish(),
  companyTeamAccess: z.boolean().nullish(),
  createdAfter: z.date().nullish(),
})

export type SpacesQueryFilters = z.infer<typeof spacesQueryFiltersSchema>

export interface SpaceQueryParams {
  includeSpaceScore: boolean
  page?: number
  pageSize?: number
  sorting?: SpaceFilterSorting
  isAscending?: boolean
  sortDirection?: 'asc' | 'desc'
  filters?: SpacesQueryFilters
}

export type SpaceFilterSorting = {
  sortBy: SpaceQuerySortBy
  sortDirection: 'asc' | 'desc'
}

export type SpaceFilterMapStatus =
  | 'notCreated'
  | 'notStarted'
  | 'stalled'
  | 'inProgress'
  | 'overdue'
  | 'completed'

export type SpaceQuerySortBy =
  | 'companyName'
  | 'mapProgress'
  | 'createdAt'
  | 'updatedAt'
  | 'ownerId'
  | 'lastVisit'
  | 'activitiesCount'
  | 'score'
  | 'visits'

interface SpacesListResponse {
  spaces: ReadSpacesListDTO[]
  totalPages: number
  totalCount: number
}

const spacesQueryFn = async (
  params: SpaceQueryParams = { includeSpaceScore: false },
): Promise<SpacesListResponse> => {
  const urlParams = new URLSearchParams()
  urlParams.set('includeActionPlanSummaryFields', 'true')

  if (params.includeSpaceScore) {
    urlParams.set('includeScores', 'true')
  }

  // Pagination
  if (params.page !== undefined) {
    urlParams.set('page', params.page.toString())
  }
  if (params.pageSize !== undefined) {
    urlParams.set('pageSize', params.pageSize.toString())
  }

  // Sorting
  if (params.sorting?.sortBy) {
    urlParams.set('sortBy', params.sorting.sortBy)
  }
  if (params.sorting?.sortDirection) {
    urlParams.set('sortDirection', params.sorting.sortDirection)
  }

  if (params.filters) {
    Object.entries(params.filters).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        if (Array.isArray(value)) {
          value.forEach((val) => urlParams.append(`${key}[]`, val))
        } else if (value instanceof Date) {
          urlParams.set(key, value.toISOString())
        } else {
          urlParams.set(key, value.toString())
        }
      }
    })
  }

  return spacesApi.get(`/spaces?${urlParams.toString()}`).then((res) => res.data)
}

interface UseSpacesQueryOptions extends SpaceQueryParams {
  enabled?: boolean
  keepPreviousData?: boolean | undefined
}

export const useSpacesQuery = (options: UseSpacesQueryOptions) => {
  const queryKey = useMemo(() => {
    return ['spaces', options]
  }, [options])

  const query = useQuery({
    queryKey,
    queryFn: () => spacesQueryFn(options),
    enabled: options.enabled,
    cacheTime: 0,
    refetchOnMount: true,
    keepPreviousData: options.keepPreviousData,
  })

  return {
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    spaces: query.data?.spaces,
    totalPages: query.data?.totalPages,
    totalCount: query.data?.totalCount,
    isFetched: query.isFetched,
  }
}
