import { cn, IonIcon } from '@valuecase/ui-components'
import { ReactNode } from 'react'

export const OnboardingItemCardWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex flex-col md:flex-row justify-center gap-6 w-full md:mt-3'>{children}</div>
  )
}

export const OnboardingItemCard = ({
  title,
  icon,
  isSelected,
  onSelect,
}: {
  title: string
  icon: string
  isSelected?: boolean
  onSelect: () => void
}) => (
  <div
    className={cn(
      'flex md:flex-col cursor-pointer rounded-lg md:w-36 md:h-40 overflow-hidden hover:outline outline-2 outline-ai-s4-from transition-all',
      {
        'outline outline-2 outline-ai-s4-from': isSelected,
      },
    )}
    onClick={onSelect}
  >
    <div className='flex items-center justify-center bg-white/50'>
      <IonIcon name={icon} className='w-[80px] h-[70px] md:w-28 md:h-28' />
    </div>
    <div className='bg-white flex items-center md:justify-center gap-2 p-4 md:py-0 md:px-2 flex-grow h-[80px] md:h-full'>
      <span className='text-xs text-center font-semibold text-grey-s5'>{title}</span>
      {isSelected && <IonIcon name='checkmark-circle' className='text-blue-s5' />}
    </div>
  </div>
)
