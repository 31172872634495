import { useMutation, useQueryClient } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

export async function markAllAsReadQueryFn() {
  await spacesApi.post(`/activity/mark-all-as-read`)
}

export function useMarkAllAsRead(props?: { onSuccess: () => void; onError: () => void }) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: markAllAsReadQueryFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['activityFeedCount'] })

      if (props?.onSuccess) {
        props.onSuccess()
      }
    },
    onError: () => {
      if (props?.onError) {
        props.onError()
      }
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    markAllAsRead: () => mutation.mutate(),
  }
}
