import { useQueryClient } from '@tanstack/react-query'
import {
  TActionPlanTaskExpanded,
  TActionPlanTasksQuery,
  actionPlanTaskPaginatedListSchema,
  actionPlanTaskPaginatedListWithMetaSchema,
} from '@valuecase/common'
import { useCallback } from 'react'

type TaskQueryKey = Readonly<
  [
    // The literal prefix
    'tasks',
    // Then each relevant field in the correct order
    TActionPlanTasksQuery['taskStatus'],
    TActionPlanTasksQuery['spaceUpdatedAfter'],
    TActionPlanTasksQuery['page'],
    TActionPlanTasksQuery['itemsPerPage'],
    TActionPlanTasksQuery['sortBy'],
    TActionPlanTasksQuery['sortOrder'],
    TActionPlanTasksQuery['search'],
    TActionPlanTasksQuery['spaceIds'],
    TActionPlanTasksQuery['ownerIds'],
    TActionPlanTasksQuery['includeTaskStatusMetadata'],
    TActionPlanTasksQuery['spaceCreatedAfter'],
  ]
>

export function taskQueryParamsToKey(params: TActionPlanTasksQuery): TaskQueryKey {
  return [
    'tasks',
    params.taskStatus,
    params.spaceUpdatedAfter,
    params.page,
    params.itemsPerPage,
    params.sortBy,
    params.sortOrder,
    params.search,
    params.spaceIds,
    params.ownerIds,
    params.includeTaskStatusMetadata,
    params.spaceCreatedAfter,
  ] as const
}

export function taskQueryKeyToParams(key: TaskQueryKey): TActionPlanTasksQuery {
  const [
    ,
    taskStatus,
    spaceUpdatedAfter,
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    search,
    spaceIds,
    ownerIds,
    includeTaskStatusMetadata,
    spaceCreatedAfter,
  ] = key

  return {
    taskStatus,
    spaceUpdatedAfter,
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    search,
    spaceIds,
    ownerIds,
    includeTaskStatusMetadata,
    spaceCreatedAfter,
  }
}

export function parseTasksResponse({
  includeTaskStatusMetadata,
  responsePayload: response,
}: Pick<TActionPlanTasksQuery, 'includeTaskStatusMetadata'> & {
  responsePayload: unknown
}) {
  return includeTaskStatusMetadata
    ? actionPlanTaskPaginatedListWithMetaSchema.parse(response)
    : actionPlanTaskPaginatedListSchema.parse(response)
}

export function safeParseTasksResponse({
  includeTaskStatusMetadata,
  response,
}: Pick<TActionPlanTasksQuery, 'includeTaskStatusMetadata'> & {
  response: unknown
}) {
  return includeTaskStatusMetadata
    ? actionPlanTaskPaginatedListWithMetaSchema.safeParse(response)
    : actionPlanTaskPaginatedListSchema.safeParse(response)
}

export const useActionPlanTaskQueryHelpers = () => {
  const queryClient = useQueryClient()
  const updateTaskInCaches = useCallback(
    (task: TActionPlanTaskExpanded) => {
      const taskQueriesData = queryClient.getQueriesData(['tasks'])
      if (!taskQueriesData || !taskQueriesData.length) {
        return
      }
      for (const [queryKey, previousQueryData] of taskQueriesData) {
        if (!Array.isArray(queryKey) || queryKey.length !== 12 || queryKey[0] !== 'tasks') {
          console.warn('Unexpected tasks query key', queryKey)
          continue
        }
        const params = taskQueryKeyToParams(queryKey as unknown as TaskQueryKey)
        const response = safeParseTasksResponse({
          includeTaskStatusMetadata: params.includeTaskStatusMetadata,
          response: previousQueryData,
        })
        if (!response.success) {
          console.error('Failed to parse task query data', response.error)
          continue
        }
        const newQueryData = {
          ...response.data,
          items: response.data.items.map((item) => (item.id === task.id ? task : item)),
        }
        queryClient.setQueryData(queryKey, newQueryData)
      }
    },
    [queryClient],
  )

  return { updateTaskInCaches }
}
