import { useSearch } from '@tanstack/react-router'

import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { tasksOverviewRoute } from '../navigation/Router'

export const useTasksFilter = () => {
  const search = useSearch({
    from: tasksOverviewRoute.id,
  })
  const { searchTimeRange } = search

  const spaceCreatedAfter = useMemo(
    () => searchTimeRange && DateTime.now().minus(searchTimeRange).startOf('day').toJSDate(),
    [searchTimeRange],
  )

  return {
    ...search,
    spaceCreatedAfter,
  }
}
