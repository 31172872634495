import {
  AvatarThumbnail,
  Badge,
  Button2,
  ButtonLink,
  cn,
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  EmptyState,
  EmptyStateAction,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
  FilterCheckboxItem,
  FilterDropdown,
  IonIcon,
  Skeleton,
} from '@valuecase/ui-components'

import { createLink, Link, useNavigate, useSearch } from '@tanstack/react-router'
import {
  Activity,
  activityTypeFilterGroups,
  formatForceEnglishDateWithLabel,
  formatTime,
  getCleanSub,
  getCompanyInitials,
  groupActivitiesByDate,
  iconForActivityType,
  TActivitesAcrossTenantPaginatedList,
  WeaklyTypedActivity,
} from '@valuecase/common'
import { useNotifications } from '@valuecase/ui-components'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo } from 'react'

import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { useCurrentTenantQuery } from '../tenant/hooks/useReadTenant'

import ActivityEmptyState from './assets/activity_empty_state.png'
import { useActivities, useActivityFeedCount } from './hooks/useActivityFeed'
import { getActivityTitle } from './utils'
import { useMarkAllAsRead } from './hooks/useMarkAllAsRead'
import { SpaceOwnerFilterDropdown } from '../filtering/SpaceOwnerFilterDropdown'
import { useAuthState } from '@/auth/auth'
import { PageHeader } from '@/ui-components/PageHeader'

const Button2Link = createLink(ButtonLink)

function addActivityIsNewFlag(activityList: Activity[]): (Activity & { isNew: boolean })[] {
  return activityList.map((activity) => {
    const spaceLastRead = activity?.rootNode?.data.lastActivityRead
      ? new Date(activity?.rootNode?.data?.lastActivityRead).valueOf()
      : 0
    return {
      ...activity,
      isNew: new Date(activity.createdAt).valueOf() > spaceLastRead,
    }
  })
}

const activityTypesFromGroups = (groups: string[]) => [
  ...new Set(
    groups
      .flatMap((group) => activityTypeFilterGroups.find((g) => g.id === group)?.types)
      .filter((t): t is Activity['type'] => !!t),
  ),
]

const LoadingSkeleton = ({ className }: { className?: string }) => {
  return (
    <div className={cn('border-b border-grey-s2 flex gap-3 py-3 px-4 items-center', className)}>
      <Skeleton className='w-10 h-10' />
      <div className='flex flex-col justify-center gap-1'>
        <Skeleton className='w-80 h-5' />
        <Skeleton className='w-40 h-3' />
      </div>
    </div>
  )
}

export const ActivityFeed = () => {
  const { success, error } = useNotifications()

  const { tenant } = useCurrentTenantQuery()

  const navigate = useNavigate()
  const { ownerIds, activityTypeGroups } = useSearch({
    from: '/dashboard/activityFeed',
  })

  const selectedOwners = useMemo(() => (ownerIds ? new Set(ownerIds) : undefined), [ownerIds])
  const selectedActivityTypeGroups = useMemo(
    () => (activityTypeGroups ? new Set(activityTypeGroups) : undefined),
    [activityTypeGroups],
  )

  const ownerFilter = useMemo(
    () => (
      <SpaceOwnerFilterDropdown
        selectedIds={selectedOwners}
        setSelectedIds={(ids) =>
          navigate({
            to: '/activityFeed',
            search: (prev) => ({ ...prev, ownerIds: ids ? [...ids] : undefined }),
          })
        }
      />
    ),
    [selectedOwners, navigate],
  )

  const activityTypesFilter = useMemo(
    () => (
      <FilterDropdown
        selectedIds={selectedActivityTypeGroups}
        setSelectedIds={(selectedGroups) => {
          navigate({
            to: '/activityFeed',
            search: (prev) => ({
              ...prev,
              activityTypeGroups: selectedGroups ? [...selectedGroups] : undefined,
            }),
          })
        }}
        searchPlaceholder={'Search activity types'}
        labelForNoSelection={'All activities'}
        labelForSelection='Activity type'
      >
        {activityTypeFilterGroups.map((group) => (
          <FilterCheckboxItem key={group.id} id={group.id}>
            <IonIcon className={'text-grey-s5 w-4 h-4'} name={group.icon} />
            {group.label}
          </FilterCheckboxItem>
        ))}
      </FilterDropdown>
    ),
    [navigate, selectedActivityTypeGroups],
  )

  const auth = useAuthState()
  const userId = useMemo(() => (auth.authorized ? getCleanSub(auth.sub) : null), [auth])

  const showResetButton = useMemo(() => {
    const selectedOwnersContainOther = [...(selectedOwners ?? [])].some((id) => id !== userId)
    return !selectedOwners || selectedOwnersContainOther || activityTypeGroups
  }, [activityTypeGroups, selectedOwners, userId])

  const resetFilters = useCallback(() => {
    navigate({
      to: '/activityFeed',
      search: (prev) => ({
        ...prev,
        ownerIds: userId ? [userId] : undefined,
        activityTypeGroups: undefined,
      }),
    })
  }, [navigate, userId])

  const {
    activities: activitiesPaginated,
    refetch,
    fetchNextPage,
    isInitialLoading,
  } = useActivities({
    itemsPerPage: 20,
    ownerIds,
    activityTypes: selectedActivityTypeGroups
      ? activityTypesFromGroups([...selectedActivityTypeGroups])
      : undefined,
  })

  const activities = useMemo(
    () => activitiesPaginated?.pages.flatMap((page) => page.items) || [],
    [activitiesPaginated],
  )

  // todo: fix typing
  const activitiesWithIsNewFlag = useMemo(
    () => addActivityIsNewFlag(activities as Activity[]),
    [activities],
  )

  const groupedActivities = useMemo(
    () => groupActivitiesByDate(activitiesWithIsNewFlag),
    [activitiesWithIsNewFlag],
  )

  const firstPageResponse = useMemo(() => activitiesPaginated?.pages[0], [activitiesPaginated])

  const hasMorePages = useMemo(
    () =>
      (firstPageResponse?.totalPages ?? Infinity) >
      (activitiesPaginated?.pages[activitiesPaginated?.pages.length - 1].currentPage ?? 1),
    [firstPageResponse, activitiesPaginated],
  )

  const activitiesExist = useMemo(() => firstPageResponse?.itemsExist ?? true, [firstPageResponse])

  const { unreadCount } = useActivityFeedCount()
  const { trackEvent } = useTrackEvent()

  const { markAllAsRead } = useMarkAllAsRead({
    onSuccess: () => {
      trackEvent({
        event: 'activities-mark_all_read',
        eventProperties: {
          indexPageName: 'Activity Feed',
          activityFeedUnreadCount: unreadCount?.unreadActivitiesCount || 0,
        },
      })
      success('Marked all activities as read')
      refetch()
    },
    onError: () => {
      error('Error marking all activities as read')
    },
  })

  useEffect(() => {
    trackEvent({
      event: 'dashboard-visit',
      eventProperties: {
        indexPageName: 'Activity Feed',
        activityFeedUnreadCount: unreadCount?.unreadActivitiesCount || 0,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const activityLink = useCallback(
    (activity: TActivitesAcrossTenantPaginatedList['items'][number]) => {
      const { rootNodeId, type, pageId, blockId, metadata } = activity

      const baseUrl = `${window.location.origin}/spaces/${rootNodeId}`

      if (type === 'SPACE_COMMENT') {
        return baseUrl
      }

      const params = new URLSearchParams()

      if (pageId) {
        params.append('page', pageId)
      }

      if (blockId) {
        params.append('block', blockId)
      }

      if (type === 'TASK_LINK_COPIED' && metadata?.taskId) {
        params.append('task', metadata.taskId)
      }

      const queryString = params.toString()
      return queryString ? `${baseUrl}?${queryString}` : baseUrl
    },
    [],
  )

  const showJustMyActivities = useMemo(() => {
    if (!auth.authorized) {
      return false
    }

    return ownerIds?.length === 1 && ownerIds?.includes(getCleanSub(auth.sub))
  }, [ownerIds, auth])

  useEffect(() => {
    if (!auth.authorized) {
      return
    }

    trackEvent({
      event: showJustMyActivities ? 'activities-filter-own' : 'activities-filter-team',
      eventProperties: {
        indexPageName: 'Activity Feed',
        activityFeedUnreadCount: !showJustMyActivities
          ? unreadCount?.unreadActivitiesCount || 0
          : undefined,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerIds])

  return (
    <div className={'flex mt-11 flex-col gap-6 w-full'}>
      <PageHeader
        title='Activity Feed'
        description={
          <>
            Check all activities inside your spaces. Want these as slack notifications?
            <br />
            Access your settings{' '}
            <Link to='/mySettings/integrations' className={'text-primary-s5 font-bold'}>
              here
            </Link>
            .
          </>
        }
      />
      <Divider />
      <>
        <div className='flex justify-between gap-2 w-full'>
          <div className='flex gap-2'>
            {ownerIds && ownerFilter}
            {activityTypeGroups && activityTypesFilter}
          </div>
          <div className='flex gap-2'>
            {unreadCount?.unreadActivitiesCount > 0 && showJustMyActivities && (
              <Button2
                variant={'plain-subtle'}
                leadingIcon='checkmark-done-outline'
                onClick={markAllAsRead}
              >
                Mark all as read
              </Button2>
            )}
            {showResetButton && (
              <Button2 variant={'plain-subtle'} leadingIcon='refresh' onClick={resetFilters}>
                Reset
              </Button2>
            )}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button2 variant={'outlined-white'} leadingIcon='options' className='ml-auto'>
                  Filters
                </Button2>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='w-[276px] flex flex-col gap-6 p-4' align='end'>
                {ownerFilter}
                {activityTypesFilter}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        {isInitialLoading ? (
          <div className='flex flex-col gap-3'>
            <Skeleton className='h-5 w-40' />
            <div className='flex flex-col bg-white rounded-lg border border-grey-s2'>
              <LoadingSkeleton />
              <LoadingSkeleton />
              <LoadingSkeleton />
              <LoadingSkeleton />
              <LoadingSkeleton />
              <LoadingSkeleton className='border-b-0' />
            </div>
          </div>
        ) : (
          <>
            {!activitiesExist && (
              <div className='w-full h-full flex flex-col gap-3 items-center'>
                <div className='flex flex-col items-center justify-center h-1/3 gap-4'>
                  <p className='text-grey-s5'>
                    No activity yet – share spaces to see your stakeholder&apos;s activity here!
                  </p>
                  <Button2Link variant={'subtle'} to='/spaces'>
                    Go to spaces
                  </Button2Link>
                </div>
                <img
                  src={ActivityEmptyState}
                  className='activity-empty-state-placeholder'
                  alt='activity feed empty state'
                />
              </div>
            )}

            {activitiesExist && activities.length === 0 && (
              <EmptyState icon={'search'} iconSize={'small'}>
                <EmptyStateHeader>
                  <EmptyStateTitle>No matches found!</EmptyStateTitle>
                  <EmptyStateDescription>
                    Try changing or clearing out your filters.
                  </EmptyStateDescription>
                </EmptyStateHeader>
                <EmptyStateAction onClick={resetFilters}>Reset filter</EmptyStateAction>
              </EmptyState>
            )}
            {Object.keys(groupedActivities).map((date) => {
              const activities = groupedActivities[date]
              return (
                <div key={date} className='flex flex-col gap-3'>
                  <h3 className='capitalize text-sm font-bold'>
                    {formatForceEnglishDateWithLabel(
                      DateTime.fromISO(date),
                      'long',
                      tenant?.payload?.locale,
                    )}
                  </h3>
                  <div className='flex flex-col'>
                    {activities.map((activity) => {
                      const spaceData = activity?.rootNode?.versions[0].asset.data

                      return (
                        <a
                          href={activityLink(activity)}
                          onClick={() => {
                            trackEvent({
                              event: 'activties-activity-view',
                              eventProperties: {
                                indexPageName: 'Activity Feed',
                              },
                            })
                          }}
                          key={activity.id}
                          rel='noreferrer'
                          target='_blank'
                          className={cn(
                            'first:rounded-t-lg last:rounded-b-lg first:border-t border-b border-l border-r border-grey-s2',
                            'bg-white hover:bg-background transition-all',
                            'py-3 px-4 flex gap-3 justify-between items-center',
                          )}
                        >
                          <div className='flex gap-4 text-sm'>
                            {!!spaceData.companyLogo_s3ObjectUrl && (
                              <AvatarThumbnail
                                type='image'
                                imageUrl={spaceData.companyLogo_s3ObjectUrl}
                                size={'LARGE'}
                              />
                            )}
                            {!spaceData.companyLogo_s3ObjectUrl && (
                              <AvatarThumbnail
                                type='gravatarInitials'
                                initials={getCompanyInitials(
                                  activity?.rootNode?.versions[0].asset.data.companyName || '',
                                )}
                                size={'LARGE'}
                              />
                            )}
                            <div className='flex flex-col'>
                              <div className='flex gap-1 items-center'>
                                <IonIcon
                                  className={'text-primary-s4 w-4 h-4'}
                                  name={iconForActivityType(activity.type)}
                                />
                                <p>
                                  {getActivityTitle(
                                    activity as unknown as WeaklyTypedActivity,
                                    tenant?.payload,
                                  )}
                                </p>
                              </div>
                              <p className='text-grey-s5'>
                                {spaceData.companyName || 'Unknown space'}
                              </p>
                            </div>
                          </div>
                          <div className='flex gap-3'>
                            {activity.isNew && <Badge variant={'subtle-info'}>NEW</Badge>}
                            <p className={'text-xs text-grey-s5'}>
                              {formatTime(
                                DateTime.fromISO(activity.createdAt),
                                tenant?.payload?.locale,
                              )}
                            </p>
                          </div>
                        </a>
                      )
                    })}
                  </div>
                </div>
              )
            })}
            {activitiesExist && activities.length > 0 && (
              <div className='w-full flex justify-center pb-6'>
                <Button2
                  variant={'subtle'}
                  disabled={!hasMorePages}
                  onClick={() => fetchNextPage()}
                >
                  Load more
                </Button2>
              </div>
            )}
          </>
        )}
      </>
    </div>
  )
}
