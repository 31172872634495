import { useEffect, useMemo, useState } from 'react'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { BuyerVisits } from './charts/BuyerVisits'
import { CreatedSpaces } from './charts/CreatedSpaces'
import { SellerAnalytics } from './charts/SellerAnalytics'
import { TimeRange } from './charts/types'
import {
  Button2,
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@valuecase/ui-components'
import { useNavigate, useSearch } from '@tanstack/react-router'

const Analytics = () => {
  const [mixpanel_pageVisitedTracked, set_mixpanel_pageVisitedTracked] = useState(false)
  const { trackEvent } = useTrackEvent()
  const nav = useNavigate()

  const { timeRange } = useSearch({ from: '/dashboard/analytics/teamActivityAnalytics' })

  const timeRangeSelectorLabel = useMemo(() => {
    switch (timeRange) {
      case '7d':
        return 'Spaces created in the last 7 days'
      case '30d':
        return 'Spaces created in the last 30 days'
      case '3m':
        return 'Spaces created in the last 3 months'
      case '6m':
        return 'Spaces created in the last 6 months'
    }
  }, [timeRange])

  //mixpanel track  page visit
  useEffect(() => {
    if (!mixpanel_pageVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Analytics page',
        },
      }).finally(() => {
        set_mixpanel_pageVisitedTracked(true)
      })
    }
  }, [mixpanel_pageVisitedTracked, trackEvent, set_mixpanel_pageVisitedTracked])

  return (
    <div className='py-11 w-full flex flex-col gap-4'>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-140'}>
          <h1 className={'text-2xl font-bold'}>Analytics</h1>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Maximize sales potential with analytics – visualize user interactions, comprehend
            prospect behavior & optimize for revenue growth. More coming soon!
          </p>
        </div>
      </div>

      <div>
        <div className='flex justify-end'>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button2 variant={'ghost'} size={'small'} trailingIcon='chevron-down'>
                {timeRangeSelectorLabel}
              </Button2>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuRadioGroup
                value={timeRange}
                onValueChange={(v) =>
                  nav({
                    to: '/analytics/teamActivityAnalytics',
                    search: { timeRange: v as Exclude<TimeRange, 'all-time'> },
                  })
                }
              >
                <DropdownMenuRadioItem value='7d'>Last 7 Days</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value='30d'>Last 30 Days</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value='3m'>Last 3 Months</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value='6m'>Last 6 Months</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <Divider />
      </div>

      <div className='flex flex-col md:flex-row gap-4 mt-2'>
        <div className='flex flex-1 relative'>
          <CreatedSpaces range={timeRange} />
        </div>

        <div className='flex flex-1 relative'>
          <BuyerVisits range={timeRange} />
        </div>
      </div>
      <div className='w-full'>
        <SellerAnalytics range={timeRange} />
      </div>
    </div>
  )
}

export default Analytics
