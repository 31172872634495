import { useMemo } from 'react'
import { TaskColumnView } from './TaskColumnView'
import { useUpcomingTasks } from '@valuecase/ui-components'
import { EmptyStateColumnWrapper } from './EmptyStateColumnWrapper'
import { useNavigate } from '@tanstack/react-router'
import { UpcomingTasksEmptyState } from './empty-states'
import { useTasksFilter } from './useTasksFilter'

export const UpcomingTasksColumn = () => {
  const navigate = useNavigate()
  const { spaceCreatedAfter, ownerIds } = useTasksFilter()
  const tasksQuery = useUpcomingTasks({ spaceCreatedAfter, ownerIds })

  const emptyState = useMemo(() => {
    return (
      <EmptyStateColumnWrapper>
        <UpcomingTasksEmptyState />
      </EmptyStateColumnWrapper>
    )
  }, [])

  return (
    <TaskColumnView
      id='dashboard-tasks-upcoming-column'
      iconName='calendar'
      iconClassName='text-blue-s4'
      title='Upcoming Due Dates'
      taskStatus='upcoming'
      tasksCount={tasksQuery.data?.totalItems || 0}
      tasks={tasksQuery.data?.items || []}
      emptyState={emptyState}
      isLoading={tasksQuery.isLoading}
      onViewAllClick={() =>
        navigate({
          to: '/tasks',
          search: (prev) => ({ ...prev, view: 'list', taskStatus: 'upcoming' }),
        })
      }
    />
  )
}
